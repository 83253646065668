import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Select } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "src/app/app.state";
import { translateAndFormat } from "src/app/i18next";
import { LocalStoreService, TableName, TableRecord } from "src/app/services/local-store/local-store.service";
import { isDefined } from "src/utils/isDefined/isDefined";
import { FilterCardComponent } from "../filter-card/filter-card.component";
import { Tab } from "../simple-tabs/simple-tabs.component";
import { getDisplayValue } from "./helpers/get-display-value";

export interface SearchTable {
	tableName: TableName;
	tableQueryName: string;
	displayTableName: string;
	fieldNames: ReadonlyArray<string>;
	displayFieldNames: ReadonlyArray<string>;
}

export interface TableFilterChip {
	displayValue: string;
	record: TableRecord;
	tableDisplayValue: string;
	tableName: string;
	tableQueryName: string;
}

enum State {
	Init,
	Searching,
	SearchCompleted,
}

export interface ViewRecord {
	record: TableRecord | null;
	displayValue: string;
}
@Component({
	selector: "app-get-table-filter",
	templateUrl: "./get-table-filter.component.html",
	styleUrls: ["./get-table-filter.component.scss"],
})
export class GetTableFilterComponent implements OnInit, OnDestroy {
	@ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;

	@Output() chip = new EventEmitter<TableFilterChip>();

	@Select(AppState.getSearchTables) searchTables$: Observable<Array<SearchTable>>;

	tabs: Array<Tab> = [];

	activeTab: Tab;

	showOverlayContainer = false;

	pattern: string;

	resetInput = false;

	subscriptions = new Subscription();

	searchState = State.Init;

	State = State;

	viewRecords: Array<ViewRecord> = [];

	placeholder = translateAndFormat("search to filter", "capitalize");

	constructor(
		private localStoreService: LocalStoreService,
		private filterCard: FilterCardComponent,
		private _eref: ElementRef,
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		this.searchTables$.pipe(filter(isDefined)).subscribe(searchTables => {
			this.tabs = [];
			this.activeTab = undefined;

			// build the tabs
			searchTables.forEach(searchTable => {
				const tab: Tab = {
					id: searchTable.tableName,
					label: searchTable.displayTableName,
					defaultActive: !this.activeTab,
					data: searchTable,
				};

				this.activeTab = this.activeTab || tab;

				this.tabs = [...this.tabs, tab];
			});
		});
	}

	onSearchInputChanged(pattern: string) {
		this.pattern = pattern;

		this.search();
	}

	onTabClicked(tab: Tab) {
		this.activeTab = tab;
		this.activeTab = tab;

		// Added logic to check if a tab is active and is different from assets tab, if yes persist the focus on active tab.
		this.tabs.forEach(existingTab => {
			existingTab.defaultActive = tab.id === existingTab.id;
		});

		this.search();
	}

	search() {
		this.searchState = State.Searching;

		this.subscriptions.add(
			this.localStoreService
				.query$(this.activeTab.data.tableName, this.pattern, this.activeTab.data.fieldNames)
				.subscribe(records => {
					const chips = new Set(this.filterCard.tableFilterChips.map(chip => chip.displayValue));

					this.viewRecords = records.map(record => {
						const displayValue = getDisplayValue(record, this.activeTab.data.displayFieldNames);
						return {
							displayValue: displayValue,
							record: record,
							selected: chips.has(displayValue),
						};
					});

					this.viewRecords.sort((a, b) => a.displayValue.localeCompare(b.displayValue));

					this.searchState = State.SearchCompleted;
				}),
		);
	}

	onClickHighlightedText(viewRecord: ViewRecord) {
		this.showOverlayContainer = false;

		this.resetInput = false;

		this.chip.emit({
			displayValue: viewRecord.displayValue,
			record: viewRecord.record,
			tableDisplayValue: this.activeTab.data.displayTableName,
			tableName: this.activeTab.data.tableName,
			tableQueryName: this.activeTab.data.tableQueryName,
		});
	}

	onHasFocus() {
		this.resetInput = false;

		this.showOverlayContainer = true;

		this.search();
	}

	@HostListener("window:click", ["$event"])
	clickOutsideOverlay(event: MouseEvent) {
		if (!this._eref.nativeElement.contains(event.target)) {
			this.showOverlayContainer = false;

			this.resetInput = false;
		}
	}

	clearInput() {
		this.resetInput = true;
	}
}
